@very-light: 200;
@light: 300;
@normal: 400;
@medium : 600;
@bold: 700;

@basefont: 1;
@font-size: @basefont + 0em;
@font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./fonts/sourcesanspro-regular.eot');
    src: url('./fonts/sourcesanspro-regular.eot?#iefix') format('embedded-opentype'),
        url('./fonts/sourcesanspro-regular.woff2') format('woff2'),
        url('./fonts/sourcesanspro-regular.woff') format('woff'),
        url('./fonts/sourcesanspro-regular.ttf') format('truetype'),
        url('./fonts/sourcesanspro-regular.svg#sourcesanspro-regular') format('svg');
    font-weight: @normal;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./fonts/sourcesanspro-semibold.eot');
    src: url('./fonts/sourcesanspro-semibold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/sourcesanspro-semibold.woff2') format('woff2'),
        url('./fonts/sourcesanspro-semibold.woff') format('woff'),
        url('./fonts/sourcesanspro-semibold.ttf') format('truetype'),
        url('./fonts/sourcesanspro-semibold.svg#sourcesanspro-semibold') format('svg');
    font-weight: @medium;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./fonts/sourcesanspro-extralight.eot');
    src: url('./fonts/sourcesanspro-extralight.eot?#iefix') format('embedded-opentype'),
        url('./fonts/sourcesanspro-extralight.woff2') format('woff2'),
        url('./fonts/sourcesanspro-extralight.woff') format('woff'),
        url('./fonts/sourcesanspro-extralight.ttf') format('truetype'),
        url('./fonts/sourcesanspro-extralight.svg#sourcesanspro-extralight') format('svg');
    font-weight: @very-light;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./fonts/sourcesanspro-bold.eot');
    src: url('./fonts/sourcesanspro-bold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/sourcesanspro-bold.woff2') format('woff2'),
        url('./fonts/sourcesanspro-bold.woff') format('woff'),
        url('./fonts/sourcesanspro-bold.ttf') format('truetype'),
        url('./fonts/sourcesanspro-bold.svg#sourcesanspro-bold') format('svg');
    font-weight: @bold;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('./fonts/sourcesanspro-light.eot');
    src: url('./fonts/sourcesanspro-light.eot?#iefix') format('embedded-opentype'),
        url('./fonts/sourcesanspro-light.woff2') format('woff2'),
        url('./fonts/sourcesanspro-light.woff') format('woff'),
        url('./fonts/sourcesanspro-light.ttf') format('truetype'),
        url('./fonts/sourcesanspro-light.svg#sourcesanspro-light') format('svg');
    font-weight: @light;
    font-style: normal;
}

@font-face {
    font-family: 'Goods ID';
    src: url('./fonts/goodsid.eot');
    src: url('./fonts/goodsid.eot?#iefix') format('embedded-opentype'),
        url('./fonts/goodsid.woff2') format('woff2'),
        url('./fonts/goodsid.woff') format('woff'),
        url('./fonts/goodsid.ttf') format('truetype'),
        url('./fonts/goodsid.svg#sourcesanspro-light') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Goods ID2';
    src: url('./fonts/goodsid2.eot');
    src: url('./fonts/goodsid2.eot?#iefix') format('embedded-opentype'),
        url('./fonts/goodsid2.woff2') format('woff2'),
        url('./fonts/goodsid2.woff') format('woff'),
        url('./fonts/goodsid2.ttf') format('truetype'),
        url('./fonts/goodsid2.svg#sourcesanspro-light') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Goods ID3';
    src: url('./fonts/goodsid3.eot');
    src: url('./fonts/goodsid3.eot?#iefix') format('embedded-opentype'),
        url('./fonts/goodsid3.woff2') format('woff2'),
        url('./fonts/goodsid3.woff') format('woff'),
        url('./fonts/goodsid3.ttf') format('truetype'),
        url('./fonts/goodsid3.svg#sourcesanspro-light') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Goods ID4';
    src: url('./fonts/goodsid4.eot');
    src: url('./fonts/goodsid4.eot?#iefix') format('embedded-opentype'),
        url('./fonts/goodsid4.woff2') format('woff2'),
        url('./fonts/goodsid4.woff') format('woff'),
        url('./fonts/goodsid4.ttf') format('truetype'),
        url('./fonts/goodsid4.svg#sourcesanspro-light') format('svg');
    font-weight: normal;
    font-style: normal;
}


html {
    -webkit-font-smoothing: antialiased;
}

body {
    font-size: @font-size;
    font-family: @font-family;
    font-weight: @normal;
    color: #333;
}
.id-font {
    font-weight: normal;
    font-style: normal;
    font-family: 'Goods ID';
    display: inline-block;
    text-transform: initial;
}
.id-font2 {
    font-weight: normal;
    font-style: normal;
    font-family: 'Goods ID2';
    display: inline-block;
    text-transform: initial;
}
.id-font3 {
    font-weight: normal;
    font-style: normal;
    font-family: 'Goods ID3';
    display: inline-block;
    text-transform: initial;
}
.id-font4 {
    font-weight: normal;
    font-style: normal;
    font-family: 'Goods ID4';
    display: inline-block;
    text-transform: initial;
}
