.button-main {
    appearance: none;
    outline: none;
    border: none;

    padding: 15px 25px;
    border: solid 1px @dark-blue;
    border-radius: 2px;

    background-color: @dark-blue;
    color: white !important;
    font-weight: @bold;
    line-height: 1.29em;

    cursor: pointer;

    transition: all 0.25s ease;

    img, svg {
        display: inline-block;
        vertical-align: middle;

        width: 20px;
        height: 20px;

        // Due to the svg viewbox
        margin-top: -2px;
        // Used to center the font & icon for the eye
        margin-right: 5px;
        margin-left: -5px;

        fill: white;
        stroke: white;

        &.rotate {
            transform: rotate(180deg);
        }

        path {
            fill: white;
        }

        line {
            stroke: white;
        }
    }



    &:hover, &.hovered {
        &:not(.disabled) {
            box-shadow: 5px 10px 20px 0 rgba(7, 27, 79, 0.4);
        }
    }
    &:active,&.pressed {
        &:not(.disabled) {
            box-shadow: 5px 5px 10px 0 rgba(7, 27, 79, 0.2);
            background: #071b4f;
        }
    }
    &.disabled {
        background-color: rgba(7, 27, 79, 0.4);
    }

    &.red {
        background-color: @red;
        border-color: @red;

        &:active,&.pressed {
            &:not(.disabled) {
                box-shadow: 5px 5px 10px 0 rgba(7, 27, 79, 0.2);
                background: darken(@red, 10%);
            }
        }
    }
}