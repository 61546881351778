* {
	box-sizing: border-box;
}

.app {
	overflow-x: hidden;
	position: relative;
	min-height: 100vh;

	&.wallet {
		background-image: linear-gradient(to top, #f4f2e9 80%, #e7e1cd);
	}

	&.warranties {
		background-color: #F4F1E9;
	}
}

body {
	background-color: white;
	max-width: 100vw;
	overflow-x: hidden;
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	min-height: 100%;
}

a {
	text-decoration: none;
	color: inherit;
	display: inline-block;
}

img, svg {
	max-width: 100%;
}

ul {
	list-style-type: none;
	padding-left: 0;
	margin: 0;
}

