.side-menu-bg {
	background-color: #f7f6f8;
}
.certificats-list-container {
	&.initial {
		.table-wrapper {
			th, td {
				&:nth-child(2) {
					display: none;
				}
			}
		}
	}
	&.transferred {
		.table-wrapper {
			th, td {
				&:nth-child(1) {
					width: 20px !important;
				}
			}
		}
	}
}
.side-menu-page-wrapper .datas-container .certificats-list-container,
.side-menu-page-wrapper .datas-container .medias-list-container {
	font-family: Source Sans Pro;
	//width: 1114px;
	width: 1153px;
	max-width: none;
	margin-left: 30px;
	color: @dark-blue3;
	font-weight: bold;
	padding-left: 0;
	h1 {
		font-weight: 800;
		font-size: 40px;
		color: #2A2A3E;
		.count {
			margin-left: 15px;
			padding: 0px 20px;
			font-size: 30px;
			font-weight: 600;
			border-radius: 8px;
			background-color: rgba(10, 27, 79, 0.14);
			display: inline-block;
		}
	}
	.top-bar {
		display: flex;
		justify-content: space-between;
		margin-top: 50px;
		margin-bottom: 15px;
		.search-form {
			position: relative;
			&:before {
				content: "e";
				font-family: 'Goods ID4';
				position: absolute;
				left: 21px;
				top: 11px;
				color: @dark-blue3;
			}
			input {
				background-color: #fff;
				border-radius: 24px;
				padding: 13px 16px;
				padding-left: 53px;
				font-size: 14px;
				width: 543px;
				border: none;
				outline: none !important;
				font-weight: normal;
				letter-spacing: 1px;
			}
		}
		.btns {
			display: flex;
			.link {
				background-color: @dark-blue3;
				color: #fff;
				border-radius: 24px;
				display: flex;
				padding: 13px 18px;
				text-transform: uppercase;
				font-size: 14px;
				margin-left: 16px;
				border: none;
				font-weight: normal;
				.id-font2 {
					margin-right: 10px;
					display: inline-block;
					transform: scale(1.2);
				}
				.more {
					display: inline-block;
					transform: scale(1.8);
					font-weight: bold;
				}
			}
		}
	}
	.table-wrapper {
		font-size: 14px;
		background-color: #fff;
		border-radius: 3px;
		padding-bottom: 16px;
		.circle {
			width: 16px;
			height: 16px;
			border-radius: 24.5px;
			border: solid 0.8px #cccccc;
			display: inline-block;
			transform: translateY(3px);
		}
		.options {
			width: 100%;
			border-bottom: solid 2px #e4e9ed;
			.selected-options, .single-selected-options {
				display: none;
				cursor: pointer;
				&.on {
					display: inline-block;
				}
				&:hover {
					background-color: #f9f9f9;
				}
			}
			ul {
				display: flex;
				align-items: stretch;
				height: 54px;
			}
			li {
				padding: 16px 25px 14px;
				position: relative;
				display: inline-block;
				cursor: pointer;
				.id-font2, .id-font4 {
					margin-top: 2px;
				}
				span {
					margin-right: 9px;
				}
				label {
					padding-left: 39px;
					transform: none !important;
					&:before, &:after {
						transform: translateY(-23px) !important;
					}
				}
				[type="checkbox"]:checked + label:after,
				[type="checkbox"]:not(:checked) + label:after
				[type="checkbox"]:checked + label:before, 
				[type="checkbox"]:not(:checked) + label:before {
					top: 1px;
				}
				.id-font, .id-font2 {
					transform: scale(1.3);
				}
				&:after {
					content: "";
					width: 1px;
					height: 24px;
					background: #e4e9ed;
					position: absolute;
					right: 0;
					//top: 10px; 
					top: 15px;
				}
				&:last-child {
					&:after {
						display: none;
					}
				}
				&:first-child:not(:last-child) {
					padding-left: 19px;

					[type="checkbox"]:checked + label, [type="checkbox"]:not(:checked) + label {
						transform: translateY(-23px);
						&:before {
							border-radius: 100%;
						}
					}
					[type="checkbox"]:checked + label:before,
					[type="checkbox"]:not(:checked) + label:before {
						width: 16px;
						height: 16px;
						border-radius: 24.5px;
						border: solid 0.8px #cccccc;
					}
					[type="checkbox"]:checked + label:after,
					[type="checkbox"]:not(:checked) + label:after {
						content: '';
						width: 8px;
						height: 8px;
						background: white !important;
						position: absolute;
						top: 30px;
						left: 5px;
						border-radius: 100%;
						-webkit-transition: all 0.2s ease;
						transition: all 0.2s ease;

						display: none;
					}
				}
			}
			.question {
				margin-left: 0;
				.infobulle {
					padding: 7px 13px;
					width: auto;
					min-width: 140px;
					left: 50%;
					text-align: center;
					border: none;
					visibility: hidden;
					margin-top: 20px;
					background-color: #fff;
					color: @dark-blue3;
					border-radius: 3px;
					letter-spacing: .2px;
					box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.2);
					&:after {
						border-top-color: #fff;
						border-width: 8px;
						margin-left: -9px;
					}
					&:before {
						display: none;
					}
				}
				&.on {
					.infobulle {
						visibility: visible;
					}
				}
			}
			.dropdown-display {
				background-color: #CFD3DC;
				border-radius: 13px;
				padding: 6px 4px 6px 12px;
				transition: all .2s;
				margin-top: -3px;
				.arrow {
					margin-left: 5px;
					display: inline-block;
				}
				&:hover {
					background-color: #0a1b4f;
					color: #fff;
				}
				+ .dropdown-options {
					display: none;
				}
				&.open {
					.arrow {
						transform: scaleY(-1) translateY(-2px);
					}
					+ .dropdown-options {
						display: block !important;
					}
				}
			}
			.dropdown-options {
				position: absolute;
				z-index: 1;
				top: 46px;
    			left: 26px;
				border-radius: 13px;
  				background-color: #e6e8ed;
  				padding: 23px 16px;
  				padding-bottom: 9px;
  				width: 224px;
  				label {
  					margin-bottom: 16px;
  				}
	  			[type="checkbox"]:checked + label, [type="checkbox"]:not(:checked) + label {
					//transform: translateY(-23px);
					&:before {
						border-radius: 3px;
					}
				}
				[type="checkbox"]:checked + label:before,
				[type="checkbox"]:not(:checked) + label:before {
					width: 16px;
					height: 16px;
					border-radius: 3px;
					border: solid 1px #071b4f;
				}
				[type="checkbox"]:checked + label:before {
					background-color: #fff;
				}
				[type="checkbox"]:checked + label:after,
				[type="checkbox"]:not(:checked) + label:after {
					content: '';
					width: 10px;
					height: 10px;
					background: #071b4f !important;
					border-radius: 2px !important;
					position: absolute;
					top: 29px;
					left: 4px;
					border-radius: 100%;
					-webkit-transition: all 0.2s ease;
					transition: all 0.2s ease;
				}
			}
		}
		table {
			margin-top: 20px;
			//letter-spacing: 0.54px;
			border-collapse: collapse;
			line-height: normal;
			//margin-right: 59px;
			width: 100%;
			[type="checkbox"]:checked + label, [type="checkbox"]:not(:checked) + label {
				transform: translateY(-23px);
				&:before {
					border-radius: 100%;
				}
			}
			[type="checkbox"]:checked + label:before,
			[type="checkbox"]:not(:checked) + label:before {
				width: 16px;
				height: 16px;
				border-radius: 24.5px;
				border: solid 0.8px #cccccc;
			}
			[type="checkbox"]:checked + label:after,
			[type="checkbox"]:not(:checked) + label:after {
				content: '';
				width: 8px;
				height: 8px;
				background: white !important;
				position: absolute;
				top: 30px;
				left: 5px;
				border-radius: 100%;
				-webkit-transition: all 0.2s ease;
				transition: all 0.2s ease;

				display: none;
			}
			th {
				text-transform: uppercase;
				text-align: left;
				font-size: 10px;
				letter-spacing: 1px;
				color: #747f9c;
				&.sort {
					.arrows {
						position: relative;
						margin-left: 2px;
					}
					.asc, .desc {
						position: absolute;
						left: 0;
						top: 3px;
						cursor: pointer;
						display: inline-block;
						font-size: 14px;
						height: 5px;
						overflow: hidden;
						span {
							display: inline-block;
    						//transform: translateY(-8px);
						}
						&:hover {
							color: #071b4f;
						}
					}
					.asc {
						transform: translateY(-3px);
						span {
							transform: translateY(-3px);
						}
					}
					.desc {
						transform: translateY(4px);
						span {
							transform: translateY(-11px);
						}
					}
				}
			}
			tr {
				//display: flex;
				//align-items: center;
				/*&:last-child {
					td {
						border-bottom: none;
					}
				}*/
				&.disabled td {
					//color: #071b4f;
					color: rgba(7,27,79,.4);
					.tooltip {
						top: 8px;
					}
				}
				&.enable td {
					.tooltip {
						top: -19px;
					}
				}
				&.selected {
					td {
						background-color: #f9f9f9;
					}
				}
				.tooltip-cell {
					position: relative;
					.tooltip {
						margin-top: -18px;
						top: 8px;
					}
				}
			}
			td {
				vertical-align: center;
				text-align: left;
				padding: 18px 0 18px;
				border-bottom: solid 1px #e4e9ed;
				&:last-child { 
					//border-bottom: none;
				}
			}
			td, th {
				&:nth-child(1) {
					width: 57px;
					padding-left: 19px;
					//border-bottom: none;
				}
				&:nth-child(2) {
					width: 43px !important;
					padding-right: 21px;
					position: relative;
					//border-bottom: none;
					cursor: pointer;
					.trans {
						font-size: 20px;
						&.red {
							color: #d60000;
						}
						&.yellow {
							color: #ffc700;
						}
						&.green {
							color: #00db36;
						}
					}
					.tooltip {
						top: 44px !important;
						white-space: nowrap;
					}
				}
				&:nth-child(3) {
					width: 148px;
					padding-right: 20px;

					cursor: pointer;
				}
				&:nth-child(4) {
					width: 100px;
					padding-right: 24px;

					cursor: pointer;
				}
				&:nth-child(5) {
					width: 105px;

					cursor: pointer;
				}
				&:nth-child(6) {
					width: 105px; //87
					padding-right: 13px; //35

					cursor: pointer;
				}
				&:nth-child(7) {
					width: 210px;
					padding-right: 20px;

					cursor: pointer;
				}
				&:nth-child(8) {
					width: 105px; //102
					padding-right: 12px;

					cursor: pointer;
				}
				&:nth-child(9) {
					width: 105px;
					padding-right: 19px;
				}
				&:nth-child(10) {
					width: 108px;
				}
				&:nth-child(11), &:nth-child(12) {
					span {
						display: inline-block;
						cursor: pointer;
						font-size: 16px;
						color: #071b4f;
					}
					a, span {
						transition: all .2s;
						&:hover {
							opacity: .5;
						}
					}
				}
				&:nth-child(11) {
					width: 44px;
				}
				&:nth-child(12) {
					width: 44px;
				}
			}
		}
	}
}
.tooltip {
	position: absolute;
	margin-left: 40px;
	background-color: #000;
	color: #fff;
	padding: 16px 22px;
	border-radius: 3px;
	margin-top: -35px;
	font-size: 14px;
	text-align: left;
	&:before {
		content: "";
		position: absolute;
		top: 50%;
		left: 0%;
		transform: translateX(-50%) translateY(-50%) rotate(45deg);
		width: 10px;
		height: 10px;
		background-color: #000;
	}
	&.white {
		background-color: #fff;
		-webkit-box-shadow: 0px 5px 14px 0px rgba(0,0,0,0.2);
		-moz-box-shadow: 0px 5px 14px 0px rgba(0,0,0,0.2);
		box-shadow: 0px 5px 14px 0px rgba(0,0,0,0.2);
		color: @dark-blue3;
		&:before {
			background-color: #fff;
		}
	}
	&.left {
		transform: translateX(-100%);
		margin-left: -20px;
		&:before {
			left: 100%;
		}
	}
	a {
		margin-bottom: 8px;
		font-weight: normal;
		display: block;
		&:last-child {
			margin-bottom: 0;
		}
		&:hover {
			opacity: .8;
		}
	}
}
.tooltip-wrapper, .tooltip-container {
	position: relative;
	.tooltip {
		left: 0;
	}
}
//.tooltip-hover {
.tooltip-container {
	cursor: help;
	.tooltip {
		display: none;
	}
	&:hover {
		.tooltip {
			display: block !important;
		}
	}
}
.tooltip-container {
	cursor: help;
	opacity: 1 !important;
	.tooltip {
		display: none;
		top: -5px;
	}
	.mouse-link {
		background: transparent;
		position: absolute;
		width: 100%;
		height: 300%;
		top: 0;
		left: 0;
		transform: translateX(-100%) translateY(-33%);
	}
	&.on {
		.tooltip {
			display: block;
		}
	}
}

.emptystate {
	text-align: center;
	padding: 120px 0;
	.title {
		font-size: 26px;
		font-weight: bold;
		color: #2A2A3E;
	}
	.button-main {
		display: inline-block;
		text-transform: uppercase;
		padding: 24px 20px;
		span {
			display: inline-block;
			margin-right: 10px;
			transform: scale(1.2);
			font-size: 14px;
		}
	}
}
// Medias list container
.side-menu-page-wrapper .datas-container .medias-list-container {
	.table-wrapper {
		table {
			td {
				border-bottom: solid 1px #e4e9ed !important;
			}
			th {
				padding-bottom: 19px;
			}
			td, th {
				&:nth-child(1) {
					width: 57px;
					padding-left: 19px;
				}
				&:nth-child(2) {
					width: 365px !important;
					padding-left: 0px;
				}
				&:nth-child(3) {
					width: 112px;
					cursor: pointer;
				}
				&:nth-child(4) {
					width: 128px;
					cursor: pointer;
				}
				&:nth-child(5) {
					width: 178px; 
					cursor: pointer;
				}
				&:nth-child(6) {
					width: 162px; 
					cursor: pointer;
					> div {
						display: flex;
					}
				}
				&:nth-child(7) {
					width: 58px;
					padding-right: 19px;
					text-align: right;
					span {
						display: inline-block;
						cursor: pointer;
						color: #071b4f;
						display: inline;
					}
					a, span {
						transition: all .2s;
						display: block;
						font-size: 16px;
						&:hover {
							opacity: .5;
						}
					}
					.tooltip-container {
						display: inline; 
						.tooltip {
							top: -53px;
							margin-left: -35px;
							a {
								min-width: 129px;
							}
						}
					}
				}
			}
		}
	}
}
// Models list container
.side-menu-page-wrapper .datas-container .models-list-container {
	.table-wrapper {
		table {
			.name {
				position: relative;
				+ .infobulle {
					padding: 16px 30px;
					width: auto;
					min-width: 140px;
					left: 50%;
					text-align: center;
					border: none;
					margin-top: 20px;
					background-color: #fff;
					color: @dark-blue3;
					border-radius: 3px;
					letter-spacing: .2px;
					box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.2);
					position: absolute;
					top: 0;
					opacity: 0;
					transition: all .2s;
					white-space:nowrap;
					transform: translateX(-50%);
					&:before, &:after {
						top: 100%;
					    left: 50%;
					    border: solid transparent;
					    content: " ";
					    height: 0;
					    width: 0;
					    position: absolute;
					    pointer-events: none;
					}
					&:after {
						border-color: rgba(255, 255, 255, 0);
						border-top-color: #fff;
						border-width: 8px;
						margin-left: -9px;
					}
					&:before {
						display: none;
					}
				}
				&:hover {
					+ .infobulle {
						display: block;
						opacity: 1;
						transform: translateX(-50%) translateY(-100%);
					}
				}
			}
			td {
				border-bottom: solid 1px #e4e9ed !important;
			}
			th {
				padding-bottom: 19px;
			}
			td, th {
				&:nth-child(1) {
					min-width: 57px;
					padding-left: 19px;
				}
				&:nth-child(2) {
					min-width: 73px;
					padding-left: 0px;
					.img-wrapper {
						width: 45px;
						height: 45px;
						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
				}
				&:nth-child(3) {
					min-width: 100px;
					cursor: pointer;
					span {
						margin-left: 0;
						display: inline-block;
						max-width: 100px;
						white-space: nowrap;
  						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
				&:nth-child(4) {
					min-width: 162px;
					cursor: pointer;
					span {
						margin-left: 0;
						display: inline-block;
						max-width: 162px;
						white-space: nowrap;
  						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
				&:nth-child(5) {
					min-width: 370px; 
					cursor: pointer;
					position: relative;
					span { 
						margin-left: 0;
						display: inline-block;
						max-width: 370px;
						display: inline-block;
						white-space: nowrap;
  						overflow: hidden;
						text-overflow: ellipsis;
						padding-right: 30px;
						margin-top: 3px;
					}
				}
				&:nth-child(6) {
					min-width: 160px; 
					cursor: pointer;
				}
				&:nth-child(7) {
					min-width: 160px; 
					cursor: pointer;
				}
				&:nth-child(8) {
					cursor: pointer;
					width: auto;
					min-width: 50px;
					.tooltip {
						margin-top: -23px;
					}
				}
			}
		}
	}
}
.big-upload-btn {
	.underline {
		font-size: 14px;
  		font-weight: 600;
  		color: #5c6889;
  		text-decoration: underline;
  		margin-bottom: 5px;
	}
	.greyline {
		font-size: 14px;
  		font-weight: 600;
  		color: #5c6889;
  		opacity: .5;
  		margin-top: 5px;
	}
}
.add-media-overlay, .add-model-overlay  {
	display: none;
}
.modal-overlay {
	.add-media, .add-model {
		color: @dark-blue3;
		.close {
			right: 20px;
			top: 20px;
			cursor: pointer;
			position: absolute;
		}
		.modal-inner {
			width: 100%;
			max-width: 580px;
			padding: 46px 49px 48px;
		}
		.title {
			color: #2a2a3e;
			font-weight: bold;
			font-size: 27px;
			margin: 0 auto 10px;
		}
		.big-upload-btn {
			padding: 85px 0;
			min-height: 219px;
		}
		.upload-img {
			width: 100%;
		}
		.path {
			max-height: 100%;
			width: 100%;
			overflow: auto;
			padding: 30px 50px;
			z-index: 1;
		}
		.big-upload-input {
			font-size: 185px;
			z-index: 1;
			cursor: pointer;
		}
		br {
			display: block !important;
		}
		.box {
			display: flex;
			margin-top: 37px;
			margin-bottom: 60px;
			padding-left: 87px;
			.part {
				width: 50%;
				text-align: left;
				.sub-title {
					color: @dark-blue3;
					font-size: 18px;
					font-weight: bold;
					letter-spacing: 0.7px;
					margin-bottom: 25px;
				}
				> div {
					margin-bottom: 12px;
					font-size: 14px;
  					font-weight: 600;
				}
			}
		}
		[type="radio"]:checked + label:before, [type="radio"]:not(:checked) + label:before,
		[type="radio"]:checked + label:after, [type="radio"]:not(:checked) + label:after,
		[type="checkbox"]:checked + label:before, [type="checkbox"]:not(:checked) + label:before,
		[type="checkbox"]:checked + label:after, [type="checkbox"]:not(:checked) + label:after {
			top: 2px;
		}
		[type="radio"]:checked + label, [type="radio"]:not(:checked) + label,
		[type="checkbox"]:checked + label, [type="checkbox"]:not(:checked) + label {
			padding-left: 27px;
		}
		[type="checkbox"]:checked + label:after, 
		[type="checkbox"]:not(:checked) + label:after {
			top: 6px !important;
		}

		[type="checkbox"]:checked + label, [type="checkbox"]:not(:checked) + label {
			//transform: translateY(-23px);
			&:before {
				border-radius: 3px;
			}
		}
		[type="checkbox"]:checked + label:before,
		[type="checkbox"]:not(:checked) + label:before {
			width: 16px;
			height: 16px;
			border-radius: 3px;
			border: solid .8px #ccc;
		}
		[type="checkbox"]:checked + label:before {
			background-color: #fff;
			border: solid .8px #071b4f;
		}
		[type="checkbox"]:checked + label:after,
		[type="checkbox"]:not(:checked) + label:after {
			content: '';
			width: 10px;
			height: 10px;
			background: #071b4f !important;
			border-radius: 2px !important;
			position: absolute;
			top: 29px;
			left: 4px;
			border-radius: 100%;
			-webkit-transition: all 0.2s ease;
			transition: all 0.2s ease;
		}

		[type="radio"]:checked + label, [type="radio"]:not(:checked) + label {
			&:before {
				border-radius: 100%;
			}
		}
		[type="radio"]:checked + label:before,
		[type="radio"]:not(:checked) + label:before {
			width: 16px;
			height: 16px;
			border-radius: 24.5px;
			border: solid .8px #ccc;
		}
		[type="radio"]:checked + label:before {
			border: solid .8px #071b4f;
		}
		[type="radio"]:checked + label:after,
		[type="radio"]:not(:checked) + label:after {
			content: '';
			width: 8px;
			height: 8px;
			background: white !important;
			position: absolute;
			top: 30px;
			left: 5px;
			border-radius: 100%;
			-webkit-transition: all 0.2s ease;
			transition: all 0.2s ease;

			display: none;
		}
		.button-main {
			padding: 24px 85px;
			font-size: 14px;
			font-weight: normal;
			text-transform: uppercase;
		}
	}
	.add-model {
		.title {
			margin-bottom: 30px;
		}
		.modal-inner {
			width: 682px;
			max-width: 682px;
			padding: 0;
			.close {
				z-index: 19;
			}
			.step {
				position: relative;
				padding: 63px 30px 183px;
				text-align: center;
				display: none;
				height: auto !important;

				position: relative;
				.help {
					position: absolute;
					top: 0;
					left: 0;
					padding: 9px 26px 9px 9px;
					background-color: #838da7;
					color: #fff;
					font-size: 14px;
					cursor: pointer;
					.id-font4 {
						display: inline-block;
						margin-right: 12px;
						transform: scale(1.2);
					}
					.label {
						text-decoration: underline;
					}
				}
			}
			.buttons {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 24px 41px;
				border-top: 1px solid rgba(186, 186, 186, 0.5);
				background-color: #fff;
				.back-link {
					text-decoration: underline;
					cursor: pointer;
					padding-left: 32px;
					position: relative;
					&:before, &:after {
						height: 2px;
						width: 10px;
						background-color: @dark-blue3;
						content: "";
						position: absolute;
						left: 0;
					}
					&:before {
						transform: rotate(45deg);
						top: 12px;
					}
					&:after {
						transform: rotate(-45deg);
						top: 6px;
					}
				}
				.button-main {
					padding: 24px 26px;
					min-width: 170px;
					text-align: center;
				}
			}
			.main-label {
				text-align: center;
				font-weight: bold;
				display: block;
				margin-right: auto;
				margin-left: auto;
				color: #2a2a3e;
				font-size: 16px;
			}
			.inputs {
				display: flex;
				justify-content: center;
				> div {
					display: inline-block;
					margin: 0 24px;
				}
			}
			.dl-link {
				color: #628dff;
				font-size: 14px;
				margin-bottom: 40px;
				text-decoration: underline;
			}
			.step1 {
				display: block;
				.title {
					max-width: 459px;
				}
				.custom-select {
					width: 340px;
					margin: 0 auto 117px;
				}
				.back-link {
					&:before, &:after {
						display: none;
					}
				}
			}
			.step2 {
				padding-bottom: 161px;
				.title {
					max-width: 469px;
				}
				.upload-wrapper, .box {
					width: 330px;
				}
				.upload-wrapper {
					margin: 0 auto;
				}
				.big-upload-btn {
					min-height: 154px;
				}
				.upload-img {
					margin-top: 0;
				}
				.path {
				    overflow: auto;
				}
				.box {
					padding-left: 0;
					margin: 35px auto 0;
					.part {
						width: 100%;
						> div {
							display: inline-block;
						}
					}
					.sub-title {
						margin-right: 34px;
					}
					.checkbox-wrapper {
						margin-right: 34px;
					}
				}
			}
			.step3 {
				padding-bottom: 19px;
				.button-main {
					max-width: 179px;
					margin: 53px auto 33px;
				}
				.init-link {
					text-decoration: underline;
					font-size: 14px;
					margin: auto;
					cursor: pointer;
				}
			}
		}
	}
}
// Transfer modal
#transfer-certificat {
	//width: 624px;
	width: 653px;
	.close-modal {
		right: inherit;
    	left: 20px;
    	top: 8px;
	}
	.sticky-buttons {
		display: none;
		width: 652px;
		/*border-top: none;
		box-shadow: none;
		.back {
			&:before {
				display: none;
			}
		}*/
	}
	.scrollbar-inner {
		
	}
	.product {
		display: flex;
		//padding: 84px 35px 35px 64px;
		//padding: 35px 35px 35px 64px;
		padding: 35px 64px 35px 64px;
		.img-wrapper {
			width: 138px;
			min-width: 138px;
			height: 137px;
			border: 1px solid rgba(192, 192, 192, 0.5);
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.col {
			width: 100%;
		    margin-left: 15px;
		    color: @dark-blue3;
		}
		.header {
			background-color: #e6e0ce;
		    text-transform: uppercase;
		    font-weight: bold;
		    font-size: 18px;
		    margin-bottom: 21px;
		    padding: 15px 10px 14px 21px;
		    letter-spacing: 1px;
		}
		.line {
			font-size: 13px;
		    padding: 11px 0;
		    border-bottom: 1px solid rgba(192, 192, 192, .5);
		    width: 100%;
		    &:first-child {
		    	padding-top: 0;
		    }
		    .value {
		    	font-weight: bold;
		    	float: right;
		    }
		}
	}
	.mail-wrapper {
		background-color: #E7E1CC;
		//padding: 24px 35px 24px 64px;
		padding: 24px 64px 24px 64px;
		margin: 0;
		label {
			text-transform: initial;
			color: @dark-blue3;
			font-size: 14px;
			letter-spacing: 0;
			padding-bottom: 0;
			margin-right: 39px;
			letter-spacing: 0.54px;
		}
	}
	.profil-wrapper {
		font-size: 14px;
		letter-spacing: 0.3px;
		padding: 0 0 0 64px;
		margin-top: 40px;
		margin-bottom: 40px;
		color: @dark-blue3;
		span {
			color: @dark-blue3;	
		}
		[type="radio"]:checked + label, [type="radio"]:not(:checked) + label {
			padding-left: 26px;
			margin-left: 20px;
		}
		[type="radio"]:checked + label:before, [type="radio"]:not(:checked) + label:before {
			top: 1px;
			transform: scale(.8);
		}
		[type="radio"]:checked + label:before{
			background-color: #fff;
		}
		[type="radio"]:checked + label:after, [type="radio"]:not(:checked) + label:after {
			top: 6px;
			background-color: @dark-blue3;
		}
		label:last-child {
			margin-left: 39px;
		}
	}
	.more-infos {
		font-size: 14px;
		letter-spacing: 0.3px;
		//padding: 0 35px 24px 64px;
		padding: 0 64px 24px 64px;
		margin-bottom: 160px;
		color: @dark-blue3;
		display: none;
		label {
			text-transform: initial;
			color: @dark-blue3;
			font-size: 14px;
			letter-spacing: 0;
			padding-bottom: 0;
			margin-bottom: 6px;
			letter-spacing: 0.54px;
		}
		.cols2 {
			display: flex;
			justify-content: space-between;
			margin-top: 24px;
			> div {
				width: calc(50% - 12px);
			}
		}
		.info {
			width: 17px;
    		height: 17px;
    		border: 1px #cbced5 solid;
    		/*.icon {
    			-webkit-transform: translateY(0px);
    			transform: translateY(0px);
    		}*/
		}
		.infobulle {
			padding: 10px 20px;
			margin: 0;
			line-height: 1.4;
			font-size: 14px !important;
			width: 356px;
			margin-left: -154px;
			&:before, &:after {
				left: calc(100% - 22px) !important;
			}
		}
		#sold-date {
			color: @dark-blue3;
		}
	}
}
.modal-overlay-flex {
	display: flex;
	align-items: center;
	height: 100%;
}
.add-certificat {
	display: flex;
	align-items: center;
	.modal-inner {
		//max-width: 580px !important;
		width: auto !important;
		max-width: 100% !important;
	}
	.upload-wrapper {
		width: 500px;
		margin-right: auto;
		margin-left: auto;
		margin-bottom: 24px;
	}
	.dl-link {
		margin-bottom: 5px !important;
	}
	.modal-flex { 
		align-items: center;
	}
	.step {
		padding: 63px 30px 153px !important;
	}
	.step1 {
		width: 580px !important;
		.title {
			max-width: 385px !important;
		}
		.big-upload-btn {
			min-height: 146px; 
		}
		label {
			font-size: 14px;
		}
	}
	.step2 {
		width: 860px !important;
		padding-bottom: 40px !important;
		.title {
			max-width: 385px;
		}
		.table-wrapper {
			width: 780px;
			margin: 42px auto 41px;
			padding: 31px 76px 22px;
			border-radius: 8px;
			border: solid 1px #d2d6e1;
			background-color: #fbfcff;
			table {
				width: 100%;
				td, th {
					text-align: left;
					&:last-child {
						text-align: right;
					}
				}
				th {
					padding-bottom: 28px;
					font-size: 13px;
					color: #5c6889;
				}
				tr {
					font-size: 14px;
					td {
						border-bottom: solid 1px #ebebeb;
					}
					&:last-child {
						td {
							border-bottom: none;
						}
					}
					td {
						padding: 12px 0;
					}
				}
			}
		}
		.button-main {
			width: 149px;
			margin-right: auto;
			margin-left: auto;
			padding-right: 12px;
			padding-left: 12px;
		}
	}
}
.btn {
	&.confirm {
		text-transform: uppercase;
	}
	&.disabled {
		background-color: rgba(7, 27, 79, 0.4) !important;
		cursor: initial !important;
	}
}
input {
	&.error {
		border-color: #ffa674;
	}
}



