body.my-certificat-template {
	background-color: #E6E0CE;
	.modal-overlay {
		position: initial;
	}
}
.modal-certificat {
	position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;
}
.modal-shadow .modal-content {
	max-width: 1085px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 40px;
	//-webkit-box-shadow: 10px 0px 25px 0px rgba(0,0,0,0.25);
	//-moz-box-shadow: 10px 0px 25px 0px rgba(0,0,0,0.25);
	//box-shadow: 10px 0px 25px 0px rgba(0,0,0,0.25);
	max-width: 100vw;
	.my-certificat-wrapper {
		box-shadow: 10px 0px 25px 0px rgba(0, 0, 0, 0.25);
		//border: 1px solid #C0C0C0;
		padding-top: 0 !important;
		.main-wrapper {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}
	}
}
.block-notes {
	background-color: #F8F6F1;
    border: #D1C4A1 1px solid;
    vertical-align: top;
    padding: 30px 50px;
	margin-bottom: 50px;
	color: @dark-blue3;
	.block-title {
		width: 100%;
		text-transform: uppercase;
		font-size: 22px;
		font-weight: bold;
	}
	.intro {
		width: 100%;
		margin: 15px 0;
		font-size: 16px;
		line-height: 1.8;
	}
	.outtro {
		width: 100%;
		margin: 15px 0;
		font-size: 16px;
		line-height: 1.8;
		text-align: right;
		a {
			text-decoration: underline;
		}
	}
    table, td {
    	font-weight: normal;
    	border-collapse: collapse;
    }
    table {
    	margin-left: 30px;
    	tr td:first-child {
    		width: 75px !important;
    	}
    }
    tr, td {
    	vertical-align: middle;
    	font-weight: normal !important;
    	img {
    		width: 66px;
		    float: left;
		    vertical-align: top;
    	}
    }
}
.powered {
	text-align: right;
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 45px;
	color: @dark-blue3;
	padding-right: 15px;
	img {
		height: 20px;
	    display: inline-block;
	    transform: translateY(5px);
	    margin-left: 5px;
	}
}
.header-title {
	margin-top: 10px;
	line-height: 1.44em;
}
.my-certificat-waiting {
	.blue-bar {
		-webkit-box-shadow: 0px -3px 4px 0px rgba(0, 0, 0, 0.2);
		-moz-box-shadow: 0px -3px 4px 0px rgba(0, 0, 0, 0.2);
		box-shadow: 0px -3px 4px 0px rgba(0, 0, 0, 0.2);
		+ .authent-block .auth-block-top-bar {
			-webkit-box-shadow: none !important;
			-moz-box-shadow: none !important;
			box-shadow: none !important;
		}
	}
}
.my-certificat-wrapper {
	//max-width: 1298px;
	max-width: 1331px;
	width: 100%;
	//display: flex;
	justify-content: space-between;
	margin-top: 80px;
	padding-left: 0 !important;
	padding-right: 0 !important;
	&.my-certificat-wrapper-no-cta {
		&.logout {
			margin-top: 50px;
			.main-wrapper {
				margin-bottom: 20px;
			}
			.historique-button {
				top: 154px !important;
			}
		}
	}
	.authent-block {
		box-shadow: none;
		cursor: auto;
	}
	.main-wrapper {
		background-color: #F7F5F5;
		width: 1090px;
		color: @dark-blue3;
		font-family: "Source sans Pro", sans-serif;
	}
	.center-wrapper {
		padding: 26px;
		overflow: auto;
		> div {
			padding: 9px 26px 26px;
			cursor: default;
		}
	}
	.breadcrumb {
		background-color: #E6E0CE;
		list-style: none;
		padding: 10px 0;
		font-size: 14px;
		li {
			display: inline-block;
			&:not(:last-child) {
				&:after {
					content: "›";
					margin: 0 5px;
				}
			}
			&:hover {
				opacity: .8;
			}
		}
	}
	.auth-block-top-bar {
		background-color: #fff;
		padding: 20px 25px;
		border-bottom: 1px solid #C0C0C0;
		.brand-name {
			padding-left: 25px;
			letter-spacing: 1px;
			font-size: 27px;
			font-weight: bold;
			font-family: "Source sans Pro", sans-serif;
			margin-top: 2px;
		}
		.certificate-id {
			transform: translateY(11px);
			//font-size: 12px;
			.logo {
				display: inline-block;
				transform: translateY(1px) scale(1.4);
			    margin-left: 5px;
			    margin-right: 8px;
			}
			.number {
				display: inline-block;
				margin-left: 10px;
			}
		}
		.bubbles {
			.bubble {
				display: inline-block;
				max-width: 85vw;
				.closed {
					display: inline-block;
					margin-right: 7px;
				    font-size: 15px;
				    margin-left: 3px;
				}
			}
		}
	}
	.title {
		float: right;
		width: 627px;
		background-color: #E6E0CE;
		color: @dark-blue3;
		padding: 17px 24px;
		padding-top: 15px !important;
		padding-bottom: 16px !important;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		position: relative;
		.small-logo {
			border-radius: 50%;
			position: absolute;
			width: 44px;
			height: 44px;
			top: 23px;
		}
		h1 {
			margin: 0;
			margin-bottom: 3px;
			padding: 0;
			font-weight: bold;
			font-family: "Source sans Pro", sans-serif;
			font-size: 22px;
			max-width: calc(100% - 72px);
			margin-left: 63px;
			//word-break: break-all;
		}
		h2 {
			margin: 0;
			padding: 0;
			font-weight: bold;
			font-family: "Source sans Pro", sans-serif;
			font-size: 18px;
			font-weight: 400;
			letter-spacing: .5px;
			margin-left: 63px;
			max-width: 100%;
			//max-width: calc(100% - 100px);
			//word-break: break-all;
		}
		&.no-icon {
			h1, h2 {
				margin-left: 0 !important;
			}
		}
		.status {
			position: absolute;
			//top: 33px;
    		//right: 18px;
    		//transform: scale(.85);
    		top: 12px;
    		right: 8px;
		}
		.icon-circle {
			margin-top: 0;
			transform: translateY(-4px) scale(.8);
			box-shadow: none;
			span {
				transform: translateY(3px) translateX(0px) scale(1);
			}
		}
	}
	.tabs {
		float: right;
		width: 627px;
		display: flex;
		text-transform: uppercase;
		font-weight: bold;
		font-size: 15px;
		letter-spacing: 1px;
		margin-bottom: 0;
		li {
			padding: 20px 0;
			width: 50%;
			text-align: center;
			background-color: #F7F5F5;
			//opacity: .5;
			cursor: pointer;
			transition: .2s all;
			-webkit-transition: .2s all;
			color: #888B99;
			&.active {
				background-color: #fff;
				opacity: 1;
				color: @dark-blue3;
			}
			&:hover {
				opacity: 1;
			}
			span {
				display: inline-block;
				margin-right: 6px;
			}
		}
	}
	.tab-target {
		float: right;
		width: 627px;
		background-color: #fff;
		&.specifications-1 {
			padding-bottom: 0;
		}
		&.specifications-2 {
			padding-top: 0;
		}
	}
	table {
		width: 100%;
		tr {
			vertical-align: top;
			td {
				//font-size: 18px;
				font-size: 16px;
				padding: 13px 0;
				&:first-child {
					width: 185px;
				}
				&:last-child {
					font-weight: bold;
					border-bottom: none;
				}
			}
		}
	}
	.specifications-1, .specifications-2 {
		table {
			border-spacing: 0;
			tr {
				td {
					border-bottom: 1px solid #C0C0C0;
				}
			}
		}
		.info {
			float: none;
			border: 1px solid #cbced5;
			width: 18px;
			height: 18px;
			transform: translateY(2px);
			margin-left: 3px;
			span {
				.icon {
					transform: translateY(-2px);
				}
			}
		}
	}
	.specifications-1 {
		position: relative;

	}
	.specifications-2 {
		table {
			tr {
				&:last-child {
					td {
						border-bottom: none;
					}
				}
			}
		}
	}
	.case-serial-hover {
		display: none;
		position: absolute;
		left: 0;
		top: 98px;
		z-index: 1;
		background-color: #fff;
		border: 1px solid #C0C0C0;
		//padding: 40px;
		//padding-top: 80px;
		padding: 10px;
		padding-top: 20px;
		text-align: center;
		margin: 0 25px;
		//transform: translateY(20px);
		//opacity: 0;
		//transition: 5s all;
		-webkit-box-shadow: 10px 0px 25px 0px rgba(0,0,0,0.25);
		-moz-box-shadow: 10px 0px 25px 0px rgba(0,0,0,0.25);
		box-shadow: 10px 0px 25px 0px rgba(0,0,0,0.25);
		.close {
			position: absolute;
			top: 15px;
			right: 15px;
			display: none;
		}
		p {
			margin-top: 5px;
		}
		.img-wrapper {
			max-width: 300px;
			max-height: 300px;
			text-align: center;
			margin: 30px auto;
			img {
				object-fit: contain;
				height: 100%;
				width: 100%;
			}
		}
		&.on {
			display: block;
			transform: none;
			opacity: 1;
		}
	}
	.images {
		//float: left;
		width: 397px;
		padding: 26px;
		background-color: #fff;
		border-radius: 5px;
		position: relative;
		overflow: hidden;
		.main-image-wrapper {
			width: 345px;
			height: 345px;
			margin-bottom: 15px;
			//border: 1px solid #E6E0CE;
			overflow: hidden;
			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
		.certificat-slider {
			width: calc(100% + 21px);
			.image {
				width: 121px;
				outline: none !important;
				img {
					width: 100px;
					height: 100px;
					outline: none !important;
					cursor: pointer;
					border: 1px solid #d3cec2;
					object-fit: contain;
				}
			}
			.slick-arrow {
				position: relative;
			}
		}
		.arrows-wrapper {
			//height: 100px;
		    //position: absolute;
		    //width: 344px;
		    z-index: 1;
		    background: -moz-linear-gradient(left,  rgba(0,0,0,.6) 0%, rgba(0,0,0,0) 12%, rgba(0,0,0,0) 88%, rgba(0,0,0,.6) 100%); /* FF3.6-15 */
		    background: -webkit-linear-gradient(left,  rgba(0,0,0,.6) 0%,rgba(0,0,0,0) 12%,rgba(0,0,0,0) 88%,rgba(0,0,0,.6) 100%); /* Chrome10-25,Safari5.1-6 */
		    background: linear-gradient(to right,  rgba(0,0,0,.6) 0%,rgba(0,0,0,0) 12%,rgba(0,0,0,0) 88%,rgba(0,0,0,.6) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000',GradientType=1 ); /* IE6-9 */
		    &:after {
		    	content: "";
		    	position: absolute;
		    	width: 26px;
		    	height: 100%;
		    	background-color: #fff;
		    	top: 0;
		    	right: -26px;
		    }
		}
		.button-arrow {
			position: absolute;
			//bottom: 76px;
			bottom: 50px;
			transform: translateY(50%);
			cursor: pointer;
			color: #fff;
			z-index: 2;
			font-weight: bold;
			width: 20px;
			height: 20px;
			&:before, &:after {
				content: "";
				background-color: #fff;
				width: 12px;
				height: 2px;
				position:absolute;
			}
			&:before {
				transform: rotate(45deg) translateY(-1px) translateX(6px);
			}
			&:after {
				transform: rotate(-45deg) translateY(11px) translateX(-4px);
			}
			&.button-prev {
				left: 35px;
				//left: 10px;
				transform: translateY(50%) scaleX(-1);
				//bottom: 75px;
				bottom: 63px;
			}
			&.button-next {
				right: 35px;
				//right: 10px;
				//bottom: 75px;
				bottom: 63px;
			}
		}
	}
	.description {
		float: right;
		width: 627px;
		background-color: #fff;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		//padding-top: 0;
		//padding-bottom: 34px !important;
		.subtitle {
			font-size: 16px;
		}
		.wrapper {
			background-color: #EBF0FB;
			padding: 0;
			.subtitle-wrapper {
				padding: 14px 24px;
				background-color: #DDE5F8;
			}
			.subtitle {
		    	text-transform: none;
		    	//font-size: 17px;
		    	letter-spacing: 0.5px;
		    	margin: 0;
			}
			.txt {
				padding: 24px;
			}
		}
		li {
			margin-bottom: 11px;
			padding-left: 18px;
			position: relative;
			width: 100%;
			font-size: 15px;
			&:before {
				content: "";
				position: absolute;
				width: 5px;
				height: 5px;
				background-color: @dark-blue3;
				left: 0;
				top: 7px;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.documents {
		//float: left;
		//margin-top: 26px;
		margin-top: 14px;
		width: 397px;
		background-color: #fff;
		border-radius: 5px;

		padding-top: 20px !important;
		.subtitle {
		    margin-top: 36px;
			&:first-of-type {
				margin-top: 0;
			}
			.id-font2 {
				margin-right: 10px;
			}
		}
		li {
			margin-bottom: 11px;
			padding-left: 27px;
			position: relative;
			&:before {
				//content: "y";
				position: absolute;
				font-family: 'Goods ID2';
				left: 3px;
				top: 2px;
				font-size: 12px;
				color: #628DFF;

				content: "";
				background-color: @dark-blue3;
				width: 5px;
				height: 5px;
				top: 7px;
			}
			&:after {
				content: "a";
				position: absolute;
				font-family: 'Goods ID2';
				right: 0;
				top: 2px;
				font-size: 12px;
				color: #628DFF;
			}
		}
		a {
			color: #628DFF !important;
			font-size: 14px;
			text-decoration: underline;
		}
	}
	.subtitle {
		font-weight: bold;
		letter-spacing: 1px;
		font-size: 15px;
		margin-bottom: 20px;
		margin-top: 0;
		text-transform: uppercase;
	}
	.btns-wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		//width: 229px;
		width: 225px;
		.overflow {
			width: 100%;
		}
		.button-main {
			//font-size: 14px;
			font-size: 16px;
			//margin-bottom: 18px;
			margin-bottom: 10px;
			//width: 190px;
			padding-left: 20px;
			position: relative;
			padding-right: 0;
			span {
				margin-right: 15px;
				transform: scale(1.2);
				position: absolute;
				left: 20px;
			}
		}
		.block {
			background-color: #fff;
			padding: 1px 10px;
			margin-bottom: 14px;
			text-align: center;

			padding: 21px 20px 11px;
			p {
				color: @dark-blue3;
				text-transform: uppercase;
				margin: 21px 0;
				font-weight: bold;
				margin-top: 0;
				font-size: 16px;
			}
		}
	}
	&.design-watchfid {
		.center-wrapper {
			background-color: #fff;
			.title {
				background-color: #8EBEE2;
				color: #fff;
			}
		}
		.main-image-wrapper {
			img {
				border: 1px solid #d3cec2;
			}
		}
		.images {
			padding-top: 0;
			img {
				border: 1px solid #d3cec2;
			}
		}
		/*.btns-wrapper {
			.button-main {
				background-color: #4C4C4C;
				border: solid 1px #4C4C4C;
			}
		}*/
		.specifications-1 {
			background-image: url(../img/watchfid.svg);
			background-size: 250px;
			background-repeat: no-repeat;
			background-position: right center;
		}
		.description {
			.wrapper {
				background-color: #F7F5F5;
				table {
					tr {
						td {
							font-size: 14px;
							padding: 7px 0;
							&:first-child {
								padding-left: 18px;
								position: relative;
								&:before {
									content: "";
									position: absolute;
									width: 5px;
									height: 5px;
									background-color: @dark-blue3;
									left: 0;
									top: 13px;
								}
							}
						}
					}
				}
			}
		}
	}
}
.my-certificat-wrapper-no-cta {
	//max-width: 1096px;
	max-width: 1076px;
	margin-top: 0px;
	transform: translateX(-10px);
	&:after {
		display: none !important;
	}
	&.my-certificat-acceptation {
		.historique-button {
			display: none;
		}
	}
	&.my-certificat-waiting {
		.historique-button {
			//top: 430px !important;
		}
		.auth-block-top-bar {
			-webkit-box-shadow: 0px -3px 4px 0px rgba(0, 0, 0, 0.2);
		-moz-box-shadow: 0px -3px 4px 0px rgba(0, 0, 0, 0.2);
		box-shadow: 0px -3px 4px 0px rgba(0, 0, 0, 0.2);
		}
	}
}
.my-certificat-wrapper:not(.my-certificat-wrapper-no-cta) {
	display: flex;
}
.blue-bar {
	//margin: 0 3px;
	background-color: #8EBEE2;
	padding: 8px 32px;
	text-align: center;
	color: @dark-blue3;
	width: calc(100% - 6px);
	p {
		line-height: 1.3;
	}
}

.top-box {
	max-width: 1089px;
	background-color: @dark-blue3;
	color: #fff;
	margin: 100px auto 30px;
	margin-bottom: 15px;
	padding: 30px;
	//padding: 30px 150px;
	position: relative;
	text-align: center;
	&.white {
		background-color: #fff;
		color: @dark-blue3;
	}
	&.secondary-top-box {
		margin-top: 0;
		padding-bottom: 10px;
	}
	&.confirmation {
		text-align: center;
		margin-bottom: 0;
		border: 1px solid #C0C0C0;
		box-shadow: 10px 0px 25px 0px rgba(0, 0, 0, 0.25);
		margin-bottom: 1rem;
		&:after {
			display: none;
		}
		.btns {
			text-align: center;
		}
	}
	&.acceptation {
		max-width: 1090px;
		.message {
			//max-width: 500px;
    		margin: 20px auto 40px;
		}
	}
	&.share {
		text-align: left;
		position: relative;
		.mini-container {
			width: 68%;
			padding-right: 30px;
		}
		.title {
			margin-bottom: 10px;
		}
		.message {
			margin: 10px 0 50px;
		}
		.input-group {
			display: flex;
			justify-content: space-between;
			max-width: 500px;
		}
		label {
			text-transform: uppercase;
			font-size: .8rem;
		}
		input[type="text"] {
			width: calc(100% - 135px);
		}
		.button-main {
			width: 117px;
			text-transform: uppercase;
			padding-left: 0;
			padding-right: 0;
			text-align: center;
		}
		.notes {
			background-image: url(../img/ui/illustrations/padlock.png);
			background-size: 50%;
			background-repeat: no-repeat;
			background-position: center;
			position: absolute;
			right: 0;
			top: 0;
			height: 100%;
			width: 33%;
			border-left: 1px solid #E6E0CE;
			display: flex;
			align-items: center;
			padding: 1rem;
			padding-left: 2rem;
			font-size: 16px;
			ul {
				list-style: square;
				li {
					&:first-child {
						margin-bottom: 1.5rem;
					}
				}
			}
		}
	}

	/*&:after {
		//top: 100%;
	    top: calc(100% - 20px);
		left: 50%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: transparent;
		border-top-color: @dark-blue3;
		border-width: 40px;
	    margin-left: -40px;
	    transform: scaleY(.5);
	}*/
	.title {
		font-weight: bold;
		font-size: 22px;
		margin-top: 0;
		text-transform: uppercase;
	}
	.btns {
		display: inherit;
	    //text-align: right;
	    text-align: center;
	    width: 100%;
		.button-main {
			//background-color: #fff;
			//color: @dark-blue3 !important;
			margin-left: 50px;
			text-transform: uppercase;
		}
	}
	.link {
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
}

// Historique
.historique {
	font-size: 15px;
	padding-top: 30px;
	padding-left: 10px;
	li {
		display: flex;
		position: relative;
		overflow: hidden;
		&:not(:last-child) {
			padding-bottom: 50px;
		}
		p {
			margin: 0;
			max-width: calc(100% - 110px);
			margin-top: 4px;
			&.p-title {
				margin-bottom: 12px;
				strong {
					//font-weight: normal;
					font-size: 17px;
				}
			}
			&.submitter {
				margin-top: 14px;
				.id-font2 {
					margin: 0 8px;
				}
			}
			&.details {
				//margin-top: 12px;
				margin-top: 0;
				margin-left: -8px;
			}
		}
		a {
			color: #628DFF !important;
			text-decoration: underline;
		}
		.icon-wrapper {
			width: 69px;
			position: relative;
			.icon {
				width: 40px;
				height: 40px;
				font-size: 21px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			&:before {
				content: "";
			    position: absolute;
			    border-left: 1px dashed #dbdbdb;
			    width: 1px;
			    height: calc(100% - 20px);
			    top: 55px;
			    left: 19px;
			}
		}
		&:last-child {
			.icon-wrapper {
				&:before {
					display: none;
				}
			}
		}
		.desc {
			width: calc(100% - 69px);
		}
		.icon {
			background-color: @dark-blue3;
			border-radius: 50%;
			color: #fff;
			text-align: center;
		}
		.light, .date, .icon-detail {
			color: #7F8CAD;
			&.date {
				top: 0;
				right: 0;
				position: absolute;
				margin: 0;
				.id-font {
					margin-left: 10px;
				}
			}
			&.icon-detail {
				transform: scaleX(0.5) translateY(6px);
			    margin-right: 0px;
			    display: inline-block;
			    font-size: 32px;
			    letter-spacing: 4px;
			}
		}
	}
}

// Historique - Modal
.modal-link {
	cursor: pointer;
}
// Modal
.modal-certificat {
	z-index: 100010 !important;
	background-color: rgba(7,27,79,.8);
	overflow: scroll !important;
	.modal-box {
		width: 100%;
		max-width: 1168px;
		background-color: #fff;
		padding: 98px 88px;
		margin: 179px auto 40px;
		color: @dark-blue3;
		border-radius: 4px;
		-ms-border-radius: 4px;
		-moz-border-radius: 4px;
		-webkit-border-radius: 4px;
		position: relative;
		.close {
			position: absolute;
			top: -30px;
			right: 0;
			cursor: pointer;
			color: #fff;
			opacity: 1;
			font-size: 14px;
			z-index: 10;
			&:before, &:after {
				background-color: #fff;
			}
		}
		> .title {
			&:not(:nth-child(2)) {
				margin-top: 59px;
			}
		}
		.title {
			font-size: 22px;
			font-weight: bold;
			letter-spacing: 1px;
			display: block;
			margin-bottom: 21px;
			position: relative;
			width: 100%;
			.info {
				float: none;
				border: #cbced5 1px solid;
				color: #cbced5;
				-webkit-transform: translateY(2px);
				transform: translateY(2px);
				width: 24px;
				height: 24px;
				text-transform: none;
				transform: translateY(4px);
				font-weight: normal;
				span {
					transform: translateY(-2px);
					display: inline-block;
				}
				.infobulle {
					margin-left: 108px;
					&:after, &:before {
						left: calc(100% - 386px) !important;
					}
				}
			}
		}
		a {
			color: #628DFF !important;
			text-decoration: underline;
		}
		.copy-paste-p {
			//width: calc(100% - 10px);
			width: 100%;
			position: relative;
			.wrapper {
				display: flex;
				width: 100%;
				margin-top: 15px;
			}
			.p {
				margin-right: 10px;
				//min-width: 250px;
				width: 100%;
				margin-bottom: 15px;
			}
			#transaction-id {
				overflow: hidden;
				color: @dark-blue3;
				display: inline-block;
				width: 100%;
				//transform: translateY(-25%);
				margin-right: 15px;
				border: 1px solid #C5D0E9;
				border-radius: 3px;
				-ms-border-radius: 3px;
				-moz-border-radius: 3px;
				-webkit-border-radius: 3px;
				//padding: 13px 11px;
				outline: none !important;
				font-size: 10px;
				height: 47px;
				padding: 17px 11px 0;
				max-height: auto;
			}
			.copy {
				//position: absolute;
				//top: -12px;
				//right: -12px;
				z-index: 1;
				width: auto;
				text-align: center;
				padding: 0 25px;
				display: flex !important;
				align-items: center;
				justify-content: center;
				span {
					font-size: 14px;
					margin-left: 8px;
				}
			}
		}
		.download {
			top: 0;
			right: 0;
			position: absolute;
			transform: translateY(-8px);
			span {
				margin-left: 5px;
			}
		}
		.data-box {
			border: 1px solid #C5D0E9;
			background-color: #EBF0FB;
			padding: 27px 30px;
			.title {
				font-weight: 900;
				text-transform: uppercase;
				font-size: 13px;
			}
			ul {
				font-size: 15px;
				li {
					margin-bottom: 11px;
					word-break: break-all;
				}
			}
		}
		.btn {
			border: 1px solid #C5D0E9;
			border-radius: 3px;
			-ms-border-radius: 3px;
			-moz-border-radius: 3px;
			-webkit-border-radius: 3px;
			padding: 13px 15px;
			background-color: #EBF0FB;
			font-size: 16px;
		    font-weight: normal;
		    letter-spacing: 0;
		    min-height: 47px;
		    cursor: pointer;

		    border: solid 1px #071b4f;
		    border-radius: 2px;
		    background-color: #071b4f;
		    font-weight: bold;
		    color: #fff;
			&:hover {
				//background-color: #C5D0E9;
				//color: @dark-blue3;
				box-shadow: 5px 10px 20px 0 rgba(7, 27, 79, 0.2);
			}
		}
	}
}
.question {
	z-index: 8;
	&.bottom {
		z-index: 9;
		.infobulle {
			//transform: translateX(-50%) translateY(calc(100% - 20px));
			transform: translateX(-50%);
			margin-top: 75px;
			&:before, &:after {
				//transform: rotate(180deg);
			}
			&:before {
				border-bottom-color: #C7C7C7;
				border-top-color: rgba(199, 199, 199, 0);
				top: -33px;
			}
			&:after {
				border-top-color: rgba(255, 255, 255, 0);
				border-bottom-color: #fff;
				top: -30px;
			}
		}
	}
}

@media only screen and (min-width : 1331px) {
	.btns-wrapper {
		//padding-top: 36px;
	}
	.top-box {
		transform: translateX(-3px);
	}

	.my-certificat-wrapper .title {
		h2 {
			white-space: nowrap;
	  		overflow: hidden;
			text-overflow: ellipsis;

		}
	}
}	
	.my-certificat-wrapper .title {

			.name {
				position: relative;
				z-index: 19;
				+ .infobulle {
					//display: block;
					padding: 16px 30px;
					width: auto;
					min-width: 400px;
					max-width: 400px;
					left: 50%;
					text-align: center;
					border: none;
					margin-top: 20px;
					background-color: #fff;
					color: @dark-blue3;
					border-radius: 3px;
					letter-spacing: .2px;
					box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.2);
					position: absolute;
					bottom: 0px;
					opacity: 0;
					//transition: all .2s;
					//white-space:nowrap;
					transform: translateX(-50%) translateX(-100%);
					z-index: 18;
					&:before, &:after {
						bottom: 100%;
					    left: 50%;
					    border: solid transparent;
					    content: " ";
					    height: 0;
					    width: 0;
					    position: absolute;
					    pointer-events: none;
					    transform: rotate(180deg);
					}
					&:after {
						border-color: rgba(255, 255, 255, 0);
						border-top-color: #fff;
						border-width: 8px;
						margin-left: -9px;
					}
					&:before {
						display: none;
					}
				}
				&:hover {
					+ .infobulle {
						display: block;
						opacity: 1;
						transform: translateX(-50%) translateY(100%);
						margin-bottom: 5px;
					}
				}
			}
	}

@media only screen and (max-width : 1330px) {
	.my-certificat-wrapper .title .name {
		& + .infobulle {
			//display: none;
		}
		//display: -webkit-box;
	    //-webkit-line-clamp: 2;
	    //-webkit-box-orient: vertical;
	    //overflow: hidden;
	}
	.blue-bar {
		margin-left: 3px;
	}
	.my-certificat-wrapper {
		flex-direction: column;
		.main-wrapper.authent-block, .btns-wrapper {
			width: 100%;
			margin-left: auto;
			margin-right: auto;
			max-width: 1090px;
		}
		.btns-wrapper {
			order: 1;
			overflow: auto;
			width: auto;
			max-width: none;
			width: 100%;
			.overflow {
				flex-direction: initial;
				display: flex;
				//display: block;
				//justify-content: space-between;
				width: 100%;
				.button-main {
					margin-right: 13px;
					display: inline-block;
					width: 150px;
					text-align: center;
					margin-right: 0;
					padding: 15px 15px;
					font-size: 14px;
					span {
						margin-right: 5px;
						position: initial;
						left: inherit;
					}
				}
				.block {
					margin-right: 3px;
					margin-bottom: 0;
					background: transparent;
					padding: 0 !important;
					width: 320px;
					p {
						display: none;
					}
					& + .block {
						width: 160px
					}
				}
			}
		}
		.main-wrapper.authent-block {
			order: 2;
		}
	}
}
@media only screen and (min-width : 1331px) { //1191
	.mob-title, .certificat-mob {
		display: none;
	}
	.historique-button {
		display: none;
	}
	.historique-list {
		padding-top: 3rem;
	}
}
@media only screen and (max-width : 1279px) { //1190 //1330
	/*.blur {
		filter: blur(2px);
	}*/
	.my-certificat-wrapper.my-certificat-wrapper-no-cta {
		transform: none;
		max-width: 1087px;
	}
	.certificat-desktop {
		display: none;
	}
	.historique {
		padding: 20px;
		li {
			.icon-wrapper {
				.icon {
					background-color: rgba(255,255,255,0.1);
					color: #628DFF;
				}
				&:before {
					border-left: 1px dashed rgba(255,255,255,0.1);
				}
			}
		}
	}
	.my-certificat-wrapper {
		margin-top: 72px;
		margin-bottom: 0;
		padding-bottom: 0;
		position: relative;
		&:after {
			display: none;
			z-index: 1;
			content: ">";
			position: fixed;
			/*left: calc(100vw - 39px);*/
			top: 70px;
			height: 56px;
			width: 60px;
    		left: calc(100vw - 42px);
    		text-align: center;
    		padding-top: 6px;
    		//top: 77px;
			color: @dark-blue3;
			font-size: 30px;
			transform: scaleX(.5);
			background: rgb(242, 242, 242);
		}
		&.my-certificat-waiting {
			.center-wrapper {
				.historique-button {
					top: 260px;
				}
			}
		}
		.case-serial-hover {
			top: 75px;
			width: calc(100% - 50px);
		}
		&.my-certificat-wrapper-no-cta {
			margin-top: 0 !important;
			.authent-block {
				margin-top: 0px !important;
			}
			.main-wrapper {
				padding-left: 0;
				padding-right: 0;
			}
		}
		&:after {
			top: 70px;
			height: 56px;
			width: 60px;
    		left: calc(100vw - 42px);
    		text-align: center;
    		padding-top: 6px;
    		//top: 77px;
			color: @dark-blue3;
			font-size: 30px;
			transform: scaleX(.5);
			background: rgb(242, 242, 242);
		}
		.breadcrumb {
			padding-left: 26px;
		}
		.certificate-id {
			margin-bottom: 34px;
			strong {
				display: none;
			}
		}
		.bubbles {
			.bubble {
				margin-left: 0;
			}
		}
		.brand-name {
			display: none;
			margin-bottom: 24px;
		}
		.auth-block-top-bar {
			display: block;
			//border-bottom: none !important;
			div {
				display: block;
				.bubble {
					display: block !important;
					margin-bottom: 12px;
					width: 290px;
				}
			}
		}
		.overflow {
			display: flex !important;
			//width: 686px !important;
			width: 516px !important;

				.button-main {
					margin-left: 4px !important;
					margin-right: 4px !important;
					margin-bottom: 12px;
				}
		}
		.btns-wrapper {
			overflow: scroll;
			align-items: inherit;
			position: relative;
			display: block;

			position: fixed;
			//background-color: #E6E0CE;
			background-color: #f2f2f2;
			-webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.75);
			-moz-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.75);
			box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.75);

			left: 0;
			top: 58px;
			padding-top: 12px;

			left: 50%;
    		transform: translateX(-50%);
		}
		.authent-block {
			//margin-top: 72px;
		}
		.center-wrapper {
			display: flex;
			flex-direction: column;
			padding: 0;
			> * {
				width: 100%;
				float: none !important;
			}
			.title {
				//background-color: #ECF0FB;
				border-radius: 0 !important;
				margin: 24px;
				width: calc(100% - 48px);
			}
			.certificat-tabs {
				display: none;
			}
			.specifications {
				table {
					tr {
						border-bottom: none;
						td {
							padding: 7px 0;
						}
					}
				}
			}
			.specifications-1 {
				order: 1;
			}
			.images {
				order: 2;
				.main-image-wrapper {
					width: 100%;
					max-width: 345px;
					margin-left: auto;
					margin-right: auto;
					display: none;
				}
				.arrows-wrapper, .certificat-slider {
					background: none;
					max-width: 345px;
					margin-left: auto;
					margin-right: auto;
				}
				.arrows-wrapper {
					display: none;
					margin-left: 50%;
					transform: translateX(calc(-50% - 27px));
				}
				.certificat-slider {
					width: 100% !important;
					img {
						max-width: 345px;
						max-height: 345px;
						width: calc(100vw - 52px);
						height: calc(100vw - 52px);
						border: none;
					}
				}
				.slick-dots {
					text-align: center;
					max-width: 345px;
					width: calc(100vw - 52px);
					li {
						display: inline-block;
						outline: none !important;
						button {
							display: inline-block;
							width: 14px;
							height: 14px;
							background-color: @dark-blue3;
							border: none;
							text-indent: -9000px;
							border-radius: 50%;
							margin: 1px;
							font-size: 0;
							display: block;
							transform: scale(.6);
							opacity: .2;
							outline: none !important;
						}
						&.slick-active {
							button {
								opacity: 1;
							}
						}
					}
				}
			}
			.specifications-2 {
				order: 3;
			}
			.description {
				table {
					tr {
						td {
							display: block;
							&:before {
								display: none;
							}
							&:first-child {
								padding-left: 0 !important;
								padding-bottom: 0;
							}
							&:last-child {
								padding-top: 0;
							}
						}
					}
				}
				order: 4;
			}
			.documents {
				margin-top: 0;
				order: 5;
			}
			.historique-button {
				position: absolute;
				left: 2px;
				top: 0;
				transform: translateX(-100%);
				background-color: @dark-blue3;
				color: #fff;
				width: 52px;
				line-height: 49px;
				font-size: 22px;
				text-align: center;
				border-radius: 3px;
				-moz-border-radius: 3px;
				-webkit-border-radius: 3px;
				cursor: pointer;
				-webkit-box-shadow: -2px 2px 8px 0px rgba(0,0,0,0.34);
				-moz-box-shadow: -2px 2px 8px 0px rgba(0,0,0,0.34);
				box-shadow: -2px 2px 8px 0px rgba(0,0,0,0.34);
			}
			.historique {
				background-color: @dark-blue3;
				color: #fff;
				order: 0;
				position: fixed;
				right: calc(-100vw + 60px);
				top: 150px;
				width: calc(100vw - 60px);
				//width: 100vw;
				padding-left: 20px;
				padding-right: 0;
				min-height: calc(100vh - 150px);
				height: calc(100vh - 150px);
				display: block !important;
				z-index: 10;

				width: calc(100vw + 60px);
				right: calc(-100vw - 60px);
				min-height: calc(100vh - 59px);
				padding-left: 0;
				&.on {
					right: 0;
					top: 59px;
					//width: 100vw;
					width: calc(100vw - 60px);
					.historique-list {
						opacity: 1;
					}
					.historique-button {
						display: none;
					}
				}
				.date {
					//right: 20px;
					position: initial;
					color: #fff;
					.id-font {
						float: left;
    					margin: 0 10px 0 0 !important;
					}
				}
				li {
					position: relative;
					padding-top: 10px;
					&:before {
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 60px;
						background-color: #486DCE;
						z-index: -1;
					}
					.icon-wrapper {
				 		width: 62px;
						&:before {
							display: none;
						}
						.icon {
							margin-left: -10px;
							color: #fff;
							width: 60px;
							height: 60px;
							font-size: 22px;
							margin-top: -10px;
							border-top-left-radius: 0;
							border-bottom-left-radius: 0;
						}
					}
					.p-title {
						margin-bottom: 30px;
						span {
							display: none;
						}
					}
					p {
						font-size: 16px;
						margin-bottom: 16px;
						.light {
							display: block;
						}
						&.files {
							a {
								display: block;
								margin-top: 7px;
							}
						}
					}
					.submitter {
						.id-font2 {
							display: none;
						}
					}
					.details {
						display: none;
					}
					.arrow {
						display: inline-block;
						margin-left: 20px;
						position: relative;
						transform: rotate(-90deg) translateX(-3px);
						&:before, &:after {
							content: "";
							position: absolute;
							background-color: #fff;
							height: 2px;
							width: 7px;
						}
						&:before {
							transform: rotate(45deg);
							top: -6px;
							right: -10px;
						}
						&:after {
							transform: rotate(-45deg);
							top: -6px;
							right: -14px;
						}
					}
					.certificat-mob {
						.modal-link {
							margin-top: 20px;
							margin-bottom: 5px;
						    display: inline-block;
						    border: 1px solid rgba(255,255,255,.3);
						    background-color: rgba(255,255,255,.1);
						    padding: 9px 26px 9px 25px;
						    text-decoration: none;
						    color: #fff !important;
						    transition: all .2s;
						    &:hover {
						    	background-color: rgba(255,255,255,.2);
						    }
						}
					}
				}
			}
			.historique-list {
				right: 0;
				overflow-y: scroll;
				height: calc(100vh - 150px);
				padding-bottom: 70px;

				opacity: 0;

				transition: opacity .6s;
				-moz-transition: opacity .6s;
				-webkit-transition: opacity .6s;
			}
			.histo-title {
				text-transform: uppercase;
				font-weight: bold;
				display: block;
				width: 100%;
				text-align: center;
				margin: 36px auto 46px;
				font-size: 18px;
    			letter-spacing: 1px;
			}
			.close {
				position: absolute;
				top: 40px;
				right: 30px;
				font-size: 20px;
				cursor: pointer;
			}
		}
	}
	.certificat-popin {
		.center-wrapper .historique {
			min-height: 100vh;
			&.on {
				top: 0;
			}
			.historique-list {
				height: calc(100vh - 111px);
			}
		}
		&:after {
			display: none !important;
		}
	}
	.top-box {
		&.share {
			.mini-container {
				width: 100%;
				padding-right: 0;
			}
			input[type="text"] {
				width: calc(100% - 128px);
			}
			.message {
				margin-bottom: 10px;
			}
			.notes {
				position: initial;
				border-left: none;
				width: 100%;
				background-size: contain;
				margin-bottom: 30px;
				justify-content: center;
				ul {
					padding: 0 10px;
					li {
						&:first-child {
							margin-bottom: 16px;
						}
					}
				}
			}
		}
	}
}
@media only screen and (min-width: 1169px) {
	.mob-br {
		display: none;
	}
	#modal-certificat {
		.btn {
			min-width: 134px;
	    	display: inline-block;
	    }
    }
    .insurance-content .insurance-content-wrapper .top-block {
    	br {
    		display: none;
    	}
    }
}
@media only screen and (max-width: 1168px) {
	#modal-certificat {
		.modal-box {
			margin: 0;
			padding: 30px 30px;
			.close {
				top: 30px;
				right: 30px;
				&:before, &:after {
					background-color: @dark-blue3;
				}
			}
			.copy-paste-p {
				.wrapper {
					.copy {
						margin-top: 0;
						//display: flex;
						align-items: center;
						line-height: 40px;
   						display: inline-block;
   						float: right;
						width: 136px;
						span {
							display: inline;
						}
					}
				}
				#transaction-id {
					height: auto;
					padding-top: 7px;
					padding-bottom: 7px;
					line-height: 1.8;
				}
			}
			/*.copy-paste-p {
				.wrapper {
					display: block;
					span {
						display: block;
					}
				}
				input {
					margin-top: -5px;
					transform: none !important;
				}
				.copy {
					display: block;
					position: initial;
				}
			}*/
			.download {
				position: initial;
				display: block;
				margin-top: 35px;
				float: right;
				width: 136px;
			}
			.data-box {
				margin-top: 77px;
			}
			.btn:not(.copy) {
				width: auto;
				display: inline-block !important;
			}
			.question {
				z-index: 1;
			}
			.question:focus .infobulle, .question:hover .infobulle {
			    display: block;
			    //top: -45px;
			    /*top: -35px;
			    transform: translateX(-50%) translateY(70%);*/
			    width: 50vw;
			    margin-left: -2px !important;
			    padding: 20px;
			    top: 0 !important;
			    margin-top: 35px;
			}
			.title {
				z-index: 1;
				&.title-3 {
					z-index: 2;
				}
				&.title-2 {
					z-index: 3;
				}
				&.title-1 {
					z-index: 4;
				}
			}
			.infobulle {
				max-width: 400px;
				top: 35px;
				transform: translateX(-50%) translateY(0%);
				&:before, &:after {
					left: calc(100% - 99px) !important;
				    top: -30px;
				    transform: rotate(180deg);
				    display: none;
				}
				&:before {
				    margin-top: -2px;
				    top: -30px;
				}
			}
		}
	}
}
@media only screen and (max-width: 1100px) {
    .my-certificat-wrapper {
    	.overflow {	
			padding: 0 10px !important;
    	}
    	.center-wrapper {
    		background-color: #fff;
    	}
    	.main-wrapper {
    		padding: 0 10px !important;
    		//background-color: #E6E0CE;
    		//background-color: #fff;
    		background-color: #E6E0CE
    	}
    	.block-notes, .powered {
    		margin-left: 10px;
    		margin-right: 10px;
    	}
    	.blue-bar {
    		margin: 0 10px;
    		width: calc(100% - 20px);
    	}
    }
    .top-box {
    	margin-top: 85px;
    	&.share, &.acceptation {
    			margin-left: 10px !important;
    			margin-right: 10px !important;
    			width: calc(100% - 20px);
    	}
    }
}
@media only screen and (max-width: 1092px) {
    .blue-bar {
    	width: 100%;
    	margin: 0 0;
    }
}
@media only screen and (max-width: 870px) {
	.modal-certificat .modal-box .copy-paste-p #transaction-id {
		//font-size: 1.1vw;
	}
	.my-certificat-wrapper .center-wrapper .title {
		padding-left: 19px;
	}

}
@media only screen and (max-width: 525px) {

	.my-certificat-wrapper {
		&:after {
			display: block;
		}
	/*	.btns-wrapper {
			overflow: auto;
			.overflow {
				.block {
				    width: 430px;
				    display: inline-block;
				}
			}
			/*.overflow {
				.block {
					width: 50%;
					margin-right: 0;
					padding-right: 3px;
					padding-left: 10px;
					text-align: center;
					.button-main {
						width: 49%;
						padding: 15px 5px;
    					font-size: 12px;
						.id-font2 {
							display: none;
						}
					}
					&:last-child {
						padding-right: 10px;
						padding-left: 0px;
					}
				}
			}*
		}*/
	}
}
@media only screen and (max-width: 665px) {
	#transaction-id {
	    min-height: 71px;
	}
	.block-notes {
		padding: 20px 30px;
		table {
			margin-left: 0;
		}
	}
	.main-header .header-title {
		top: 0;
	}
	.main-header.wallet-header .profile-wrapper .common-squared-icon {
		height: 33px;
		top: 13px !important;
	}
	.breadcrumb {
		display: none;
	}
	.auth-block-top-bar {
		.certificate-id {
		   line-height: 1.7;
			.number {
				margin-left: 0 !important;
			}
		}
	}
	.my-certificat-wrapper {
		.case-serial-hover {
			overflow: scroll;
			position: fixed;
			//height: 100vh;
			width: calc(100vw - 10px) !important;
			top: 50%;
			transform: translateY(-50%) !important;
			left: 5px;
			margin: 0;
			&.on {
				display: flex;
				align-items: center;
			}
			.infobulle {
				max-width: 100%;
			}
			.img-wrapper {
				margin-bottom: 0;
			}
			.close {
				display: block !important;
			}
		}
	}
	.specifications-1 {
		background-image: none !important;
	}
	.historique {
		li {
			.p-title {
				max-width: 100%;
				strong {
					display: block;
				}
			}
			p {
				&:not(.p-title) {
					max-width: 100%;
				}
			}
		}
	}
	.shop-card-open {
		.form .settings-wrapper .title-block .info .infobulle {
			width: 300px;
			padding: 20px;
			margin-left: -40px;
			&:before, &:after {
				left: calc(100% - 110px) !important;
			}
		}
	}
}
@media only screen and (max-width: 515px) {
	/*.historique {
		li {
			.icon-wrapper {
				width: 59px;
			}
			.desc {
				.date {
					position: relative;
					margin-bottom: 12px;
					padding-left: 20px;
				}
			}
		}
	}*/
}
@media only screen and (max-width: 480px) {
	.my-certificat-wrapper {
		margin-top: 62px;
	}
	#transaction-id {
	    min-height: 88px;
	}
}
@media only screen and (max-width: 450px) {
	/*.my-certificat-wrapper {
		.btns-wrapper {
			.overflow {
				display: block;
				.block {
					width: 100%;
					margin-right: 0;
					padding-right: 5px;
					padding-left: 5px;
					text-align: center;
					.button-main {
						width: 49%;
						padding: 15px 5px;
    					font-size: 12px;
						.id-font2 {
							display: none;
						}
					}
					&:last-child {
						padding-right: 5px;
						padding-left: 5px;
					}
				}
			}
		}
	}*/
}
@media only screen and (min-width: 421px) {
	.top-box {
		&.share {
			.cancel {
				display: none;
			}
		}
	}
}
@media only screen and (max-width: 420px) {
	.copy-paste-p {
		.wrapper {
			display: block !important;
		}
		#transaction-id {
		    min-height: 71px;

		}
	}
	body div#modal-certificat {
		div.modal-box {
			.copy {
				margin-bottom: 50px
			}
			span.btn:not(.fake-copy) {
				width: 120px !important;
			    font-size: 13px !important;
			    display: flex !important;
			    align-items: center !important;
			    float: right;
			}
			.title-3 {
				margin-top: 109px;
			}
		}
	}
	.historique {
		li {
			.desc {
				p.details {
					margin-left: 0;
					margin-top: 10px;
					.icon-detail {
						display: none;
					}
				}
			}
		}
	}
	.top-box {
		&.share {
			.title {
				margin-bottom: 20px;
			}
			.input-group {
				display: block;
				flex-direction: column;
				justify-content: flex-end;
				text-align: right;
				margin-top: 10px;
			}
			input[type="text"] {
				width: 100%;
				margin-bottom: 20px;
			}
			.cancel {
				display: inline-block;
				margin-right: 40px;
				text-decoration: underline;
				&:hover {
					text-decoration: none;
				}
			}
		}
		&.acceptation {
			padding: 20px;
			.btns {
				.link {
					margin-bottom: 30px;
				}
				a {
					//display: block;
					display: inline-block;
					margin-left: 0 !important;
					font-size: 14px;
				}
				.button-main {
					padding-left: 10px;
					padding-right: 10px;
					margin-left: 30px !important;
				}
			}
		}
	}
}
@media only screen and (max-width: 390px) {
	.my-certificat-wrapper .center-wrapper .historique.on {
		width: 100vw;
	}
}
@media only screen and (max-width: 360px) {
	.historique {
		padding-left: 15px;
		li {
			.icon-wrapper {
				width: 49px;
				&:before {
					height: calc(100% - 10px);
				    top: 45px;
				    left: 15px;
				}
				.icon {
					width: 30px;
    				height: 30px;
    			    font-size: 17px;
				}
			}
			.desc {

			}
		}
	}
}
@media only screen and (max-width: 350px) {
	.copy-paste-p {
		#transaction-id {
		    min-height: 88px;
		}
	}
}






