.side-menu-2-container {
	width: 255px;
	min-width: 255px;
	//height: 100%;
	min-height: 100vh;
	background-color: #fff;
	position: relative;
	color: @dark-blue3;
	-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
	-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
	margin-right: 50px;
	&:before {
		position: absolute;
		content: "";
		top: 0;
		left: 0;
		width: 45px;
		height: 100%;
		background-color: @dark-blue3;
	}
	.account-part, ul {
		position: relative;
		z-index: 1;
	}
	.account-part {
		padding-top: 2.2rem;
		padding-left: 58px;
		.name {
			margin: 0;
			margin-bottom: 3px;
			font-size: 17px;
			font-weight: bold;
			line-height: 1.3;
			position: relative;
		}
		.logo {
			position: absolute;
			//top: 14px;
			top: 49px;
			left: 16px;
			font-size: 14px;
			&:before {
				content: "";
				position: absolute;
				z-index: -1;
				background-color: #fff;
				border-radius: 50%;
				width: 24px;
				height: 24px;
				top: -4px;
				left: -6px;
				transform: scale(1.05) translateX(2%);
			}
		}
		.account-link {
			margin: 0;
			a {
				font-size: 12px;
	    		color: #747f9c;
	    		text-decoration: underline;
	    	}
		}
	}
	.nav {
		font-weight: bold;
		margin-top: 20px;
		.item {
			margin-top: 10px;
			font-size: 14px;
			letter-spacing: -0.2px;
			position: relative;
			.link {
				position: relative;
				transition: all .2s;
    			padding: 14px 0;
				padding-left: 58px;
				width: 100%;
				&:hover {
					background-color: #fff;
					transform: translateY(-6px) translateX(10px);		
					-webkit-box-shadow: -7px 11px 10px 0px rgba(0,0,0,0.1);
					-moz-box-shadow: -7px 11px 10px 0px rgba(0,0,0,0.1);
					box-shadow: -7px 11px 10px 0px rgba(0,0,0,0.1);
					.id-font, .id-font2, .id-font3, .id-font4 {
						background-color: #000;
						transition: all .2s;
					}
					&:after {
						border-top: 16px solid rgba(10, 27, 79, 0.14);
						border-right: 10px solid transparent;
					}
				}
				&:after { 
					content: "";
					display: inline-block;
					height: 0;
					width: 0;
					border-top: 16px solid transparent;
					border-right: 0px solid transparent;
					position: absolute;
					right: 0px;
					bottom: -16px;
					transition: all .2s;
					z-index: -2;
				}
				.count {
					right: 14px;
				}
			}
			.count {
				margin-left: 18px;
		   		font-size: 12px;
		    	font-weight: bold;
		    	display: inline-block;
		    	position: absolute;
		    	z-index: 1;
		    	padding: 0 3px;
		    	color: #FB9975;
		    	float: right;
		    	right: 0;
    			transform: scale(1.05);
		    	&:after {
		    		content: "";
		    		background-color: #FEEFEA;
		    		width: 100%;
		    		height: 100%;
		    		border-radius: 3px;
		    		position: absolute;
		    		top: 0;
		    		left: 0;
		    		z-index: -1;
		    		transform: scale(1.8);
		    	}
			}
			.id-font, .id-font2, .id-font3, .id-font4 {
				position: absolute;
				color: #fff;
				z-index: 1;
				top: 0;
			    left: 0;
			    height: 100%;
			    width: 45px;
			    display: flex;
			    align-items: center;
			    justify-content: center;
			    background-color: @dark-blue3;
				font-size: 19px;
			}
			.id-font, .id-font2, .id-font3 {
				font-size: 16px;	
			}
			.submenu {
				font-size: 12px;
				letter-spacing: .2px;
				padding: 0 8px;
				margin-top: 0px;
				font-weight: normal;
				margin-left: 58px;
				li {
					padding: 12px 6px;
					a {
						transition: all .2s;
						position: relative;
						width: 100%;
						display: inline-block;
						&:before {
							content: "";
							position: absolute;
							left: -14px;
							top: -9px;
							width: 3px;
							height: calc(100% + 18px);
							background-color: #EFF0F4;
							transition: .2s all;
						}
						&:hover {
							&:before {
								background-color: @dark-blue3;
							}
						}
						&.current {
							font-weight: bold;
							&:before {
								background-color: @dark-blue3;
							}
						}
					}
				}
			}
		}
	}
}

.site-menu-wrapper {
	position: sticky;
	top: 0;
	left: 0;
}



